import React, {Fragment, useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {Modal, Checkbox, DateField, ButtonPrimary, useStyles, LabelWithIcon, FileInput} from '@ace-de/ui-components';
import {InteractiveIcon, closeIcon, calendarIcon, warningIcon, infoAlertIcon, Icon, attachmentIcon, deleteIcon, uploadIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import allowedAttachmentMIMETypes from '../allowedAttachmentMIMETypes';
import config from '../../config';
import style from './CreateNewTimePeriodModal.module.scss';
import useCloseOnEscape from '../../utils/useCloseOnEscape';

const checkOverlap = (validFrom, validUntil, timePeriods) => {
    const momentFrom = moment(validFrom, 'YYYY-MM-DD');
    const momentUntil = moment(validUntil, 'YYYY-MM-DD');

    if (momentUntil.isSameOrBefore(momentFrom)) {
        return true;
    }

    return timePeriods.some(timePeriod => {
        const periodFrom = moment(timePeriod.validFrom, 'YYYY-MM-DD');
        const periodUntil = moment(timePeriod.validUntil, 'YYYY-MM-DD');
        return (
            momentFrom.isBetween(periodFrom, periodUntil)
            || momentUntil.isBetween(periodFrom, periodUntil)
            || momentFrom.isSame(periodFrom)
            || momentFrom.isSame(periodUntil)
            || momentUntil.isSame(periodUntil)
            || momentUntil.isSame(periodFrom)
            || periodFrom.isBetween(momentFrom, momentUntil)
            || periodUntil.isBetween(momentFrom, momentUntil)
        );
    });
};

const checkIfTariffPeriodValid = (validFrom, validUntil) => {
    const momentFrom = moment(validFrom);
    const momentUntil = moment(validUntil);

    return momentFrom.year() === 2024 && momentUntil.year() === 2025;
};

const CreateNewTimePeriodModal = props => {
    const {cx} = useStyles(props, style);
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('create_new_time_period_modal');
    const {hasBackdrop, latestTimePeriod, timePeriods} = props;
    const {declineCreateNewTimePeriodFlow, confirmCreateNewTimePeriodFlow} = props;
    const [validFrom, setValidFrom] = useState(latestTimePeriod
        ? moment(latestTimePeriod.validUntil, 'YYYY-MM-DD').add(1, 'days').toString()
        : '');
    const [validUntil, setValidUntil] = useState(latestTimePeriod
        ? moment(latestTimePeriod.validUntil, 'YYYY-MM-DD').add(1, 'years').toString()
        : '');
    const [copyPrices, setCopyPrices] = useState();
    const [areDatesValid, setAreDatesValid] = useState(true);
    const [isPeriodTariffValid, setIsPeriodTariffValid] = useState(!checkIfTariffPeriodValid(validFrom, validUntil));
    const [errorList, setErrorList] = useState([]);
    const [fileList, setFileList] = useState([]);

    const isCreationEnabled = !validFrom
        || !validUntil
        || !areDatesValid
        || !isPeriodTariffValid
        || (areDatesValid && (!copyPrices && fileList.length === 0));

    const handleOnClick = () => {
        const searchQueryParams = new URLSearchParams();

        searchQueryParams.append(`validFrom`, moment(validFrom).format('YYYY-MM-DD').toString());
        searchQueryParams.append(`validUntil`, moment(validUntil).format('YYYY-MM-DD').toString());

        if (copyPrices && fileList.length === 0) {
            confirmCreateNewTimePeriodFlow({searchQueryParams});
            return;
        }

        if (!copyPrices && fileList.length > 0) {
            const file = new Blob(fileList, {type: 'text/html'});
            confirmCreateNewTimePeriodFlow({searchQueryParams, file});
        }
    };

    const validateDates = useCallback(() => {
        if (moment(validFrom).isSame(validUntil)) {
            setAreDatesValid(false);
            return;
        }

        if (checkOverlap(validFrom, validUntil, timePeriods)) {
            setAreDatesValid(false);
            return;
        }

        if (checkIfTariffPeriodValid(validFrom, validUntil)) {
            setIsPeriodTariffValid(false);
            return;
        }

        setAreDatesValid(true);
        setIsPeriodTariffValid(true);
    }, [validFrom, validUntil, setAreDatesValid, setIsPeriodTariffValid, timePeriods]);

    useEffect(() => {
        validateDates();
    }, [validateDates]);

    const handleOnFileUpload = files => {
        const uploadFileList = Array.from(files || []);
        const uploadErrorList = [];
        if (uploadFileList.length === 0) {
            setErrorList([]);
            setFileList([]);
            return;
        }
        if (uploadFileList.length > 1) {
            uploadErrorList.push('global.aps_file_assets_error_type.max_amount_error');
        }

        if (uploadFileList.some(file => file.size > config.MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES)) {
            uploadErrorList.push('global.aps_file_assets_error_type.max_size_error');
        }

        if (uploadFileList.some(file => !allowedAttachmentMIMETypes.includes(file.type))) {
            uploadErrorList.push('global.aps_file_assets_error_type.allowed_mime_type_error');
        }

        if (uploadErrorList.length) {
            setErrorList(uploadErrorList);
            return;
        }
        setErrorList([]);
        setFileList(uploadFileList);
    };
    const deleteFile = () => {
        setFileList([]);
    };

    useCloseOnEscape(() => {
        declineCreateNewTimePeriodFlow();
    });

    return (
        <Modal
            title={translateModal('title.new_time_period')}
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineCreateNewTimePeriodFlow}
                />
            )}
            contentClassName={cx('global!ace-u-modal-content-size--s')}
        >
            <Fragment>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-margin--top-24',
                    ])}
                >
                    <div>
                        <DateField
                            name="validFrom"
                            label={translateModal('date_field_label.valid_from')}
                            icon={calendarIcon}
                            className={cx('global!ace-u-margin--right-24')}
                            value={validFrom || ''}
                            onChange={setValidFrom}
                            isFieldRequired={true}
                        />
                        <DateField
                            name="validUntil"
                            label={translateModal('date_field_label.valid_until')}
                            icon={calendarIcon}
                            value={validUntil || ''}
                            onChange={setValidUntil}
                            isFieldRequired={true}
                        />
                        {!areDatesValid && (
                            <p
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-margin--top-16',
                                    'global!ace-u-typography--color-warning',
                                    'global!ace-u-typography--variant-caption',
                                    'global!ace-u-flex--align-center',
                                ])}
                            >
                                <Icon
                                    icon={warningIcon}
                                    className={cx([
                                        'global!ace-u-margin--right-8',
                                        'ace-c-icon--s',
                                        'ace-c-icon--color-warning',
                                    ])}
                                />
                                <span>
                                    {translateModal('error_message.overlapping_dates')}
                                </span>
                            </p>
                        )}
                        {!isPeriodTariffValid && (
                            <p
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-margin--top-16',
                                    'global!ace-u-typography--color-warning',
                                    'global!ace-u-typography--variant-caption',
                                    'global!ace-u-flex--align-center',
                                ])}
                            >
                                <Icon
                                    icon={warningIcon}
                                    className={cx([
                                        'global!ace-u-margin--right-8',
                                        'ace-c-icon--m',
                                        'ace-c-icon--color-warning',
                                    ])}
                                />
                                <span>{translateModal('error_message.mixed_tariff_periods')}</span>
                            </p>
                        )}
                    </div>
                    <Checkbox
                        name="copyPrices"
                        className={cx('global!ace-u-margin--24-0')}
                        isSelected={!!copyPrices}
                        isDisabled={fileList.length > 0}
                        onChange={value => {
                            setCopyPrices(value);
                            if (fileList.length > 0) {
                                setFileList([]);
                            }
                        }}
                        value={true}
                    >
                        {translateModal('checkbox_label.copy_prices_from_last_period')}
                    </Checkbox>
                    {!copyPrices && (
                        <div className={cx('global!ace-u-grid-column--span-7', 'global!ace-u-margin--top-16')}>
                            <FileInput
                                fileList="period-prices-document"
                                isMultiple={false}
                                className={cx([
                                    'ace-c-file-upload-button',
                                    'global!ace-u-cursor--pointer',
                                ])}
                                onChange={handleOnFileUpload}
                            >
                                <LabelWithIcon
                                    className={cx([
                                        'ace-c-label-with-icon--icon-reverse',
                                        'global!ace-u-typography--variant-highlight',
                                    ])}
                                    label={translateModal('file_input_label.please_upload_file')}
                                >
                                    <Icon className={cx('ace-c-icon--color-highlight')} icon={uploadIcon} />
                                </LabelWithIcon>
                            </FileInput>
                        </div>
                    )}
                </div>
                <div
                    className={cx([
                        'global!ace-u-width--full',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--top-32',
                        'global!ace-u-flex--justify-space-between',
                    ])}
                >
                    <div>
                        {errorList.length > 0 && (
                            <div className={cx('global!ace-u-margin--top-8')}>
                                {errorList.map((errorInfo, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-margin--4-0',
                                                'global!ace-u-typography--variant-caption',
                                                'global!ace-u-typography--color-warning',
                                            ])}
                                        >
                                            <Icon
                                                icon={infoAlertIcon}
                                                className={cx([
                                                    'global!ace-u-margin--right-4',
                                                    'ace-c-icon--s',
                                                    'ace-c-icon--color-warning',
                                                ])}
                                            />
                                            {translate(errorInfo)}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {fileList.length > 0 && (
                            <div>
                                {fileList.map((fileAsset, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-flex--justify-space-between',
                                                'global!ace-u-margin--8-0',
                                            ])}
                                        >
                                            <span
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                    'global!ace-u-typography--variant-body',
                                                    'global!ace-u-typography--color-highlighted',
                                                ])}
                                            >
                                                <Icon
                                                    icon={attachmentIcon}
                                                    className={cx([
                                                        'global!ace-u-margin--right-8',
                                                        'ace-c-icon--xs',
                                                        'ace-c-icon--color-highlight',
                                                    ])}
                                                />
                                                {fileAsset.name}
                                            </span>
                                            <Icon
                                                onClick={() => deleteFile(fileAsset)}
                                                icon={deleteIcon}
                                                className={cx([
                                                    'ace-c-icon--xs',
                                                    'ace-c-icon--color-medium-emphasis',
                                                ])}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <ButtonPrimary
                        onClick={handleOnClick}
                        isDisabled={isCreationEnabled}
                    >
                        {translateModal('button_label.create')}
                    </ButtonPrimary>
                </div>
            </Fragment>
        </Modal>
    );
};

CreateNewTimePeriodModal.propTypes = {
    hasBackdrop: PropTypes.bool.isRequired,
    confirmCreateNewTimePeriodFlow: PropTypes.func.isRequired,
    declineCreateNewTimePeriodFlow: PropTypes.func.isRequired,
    latestTimePeriod: PropTypes.object,
    timePeriods: PropTypes.array,
};

CreateNewTimePeriodModal.defaultProps = {
    latestTimePeriod: null,
    timePeriods: [],
};

const mapStateToProps = state => {
    return {
        latestTimePeriod: state.priceManagement.latestTimePeriod,
        timePeriods: state.priceManagement.timePeriods,
    };
};

const mapDispatchToProps = dispatch => ({
    confirmCreateNewTimePeriodFlow: payload => dispatch({
        type: priceManagementActionTypes.CONFIRM_CREATE_TIME_PERIOD,
        payload,
    }),
    declineCreateNewTimePeriodFlow: () => dispatch({
        type: priceManagementActionTypes.DECLINE_CREATE_TIME_PERIOD,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewTimePeriodModal);
